






































































































































.handle_titles {
  display: flex;
  gap: 8px;
}
